<template>
  <div>
    <el-form
      :model="release"
      label-width="110px"
      label-position="left"
      ref="releaseForm"
      :rules="rules"
    >
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item label="Version" prop="version">
            <el-input
              v-model="release.version"
              size="mini"
              clearable
              style="width: 100%;"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item label="Release Date" prop="releaseDate">
            <el-date-picker
              v-model="release.releaseDate"
              type="date"
              placeholder="Date"
              size="mini"
              clearable
              style="width: 100%;"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item label="Linux" prop="linuxDownload">
            <el-input
              clearable
              v-model="release.linuxDownload"
              size="mini"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item label="Windows" prop="windowsDownload">
            <el-input
              clearable
              v-model="release.windowsDownload"
              size="mini"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item label="Release Note HTML" prop="releaseNoteHtml">
            <el-input
              clearable
              v-model="release.releaseNoteHtml"
              size="mini"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item label="Release Note XML">
            <el-input
              clearable
              v-model="release.releaseNoteXml"
              size="mini"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <div style="width: 100%; height: 30px; line-height: 30px; float:left;">
          <el-button
            type="primary"
            style="float: right;"
            size="mini"
            @click="handleConfirm"
            >Confirm</el-button
          >
          <el-button
            style="float:right; margin-right: 10px;"
            size="mini"
            @click="handleCancel"
            >Cancel</el-button
          >
        </div>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import http from "../utils/http";
export default {
  name: "ReleaseDetail",
  props: {
    editRelease: {},
  },
  data() {
    return {
      release: {
        _id: "",
        version: "",
        releaseDate: "",
        linuxDownload: "",
        windowsDownload: "",
        releaseNoteHtml: "",
        releaseNoteXml: "",
      },
      rules: {
        version: [
          { required: true, message: "Please input version.", trigger: "blur" },
          // {
          //   min: 2,
          //   max: 100,
          //   message: "2 to 100 letters",
          //   trigger: "blur",
          // },
        ],
        releaseDate: [
          { required: true, message: "Please input date.", trigger: "blur" },
        ],
        linuxDownload: [
          {
            required: true,
            message: "Please input linux download url.",
            trigger: "blur",
          },
        ],
        windowsDownload: [
          {
            required: true,
            message: "Please input windows download url.",
            trigger: "blur",
          },
        ],
        releaseNoteHtml: [
          {
            required: true,
            message: "Please input release note",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    if (JSON.stringify(this.editRelease) != "{}") {
      let e = this.editRelease;
      this.release._id = e._id;
      this.release.version = e.version;
      this.release.releaseDate = e.releaseDate;
      this.release.linuxDownload = e.linuxDownload;
      this.release.windowsDownload = e.windowsDownload;
      this.release.releaseNoteHtml = e.releaseNoteHtml;
      this.release.releaseNoteXml = e.releaseNoteXml;
    }
  },
  methods: {
    handleConfirm() {
      this.$refs.releaseForm.validate((valid) => {
        if (valid) {
          if (this.release._id == "" || this.release._id == null) {
            http.post("/release/create", this.release).then((res) => {
              if (res.data.status == 1) {
                this.$message({
                  type: "success",
                  message: "The release has been created successfully!",
                });
                this.$emit("confirm");
              } else {
                this.$message.error(res.data.message);
              }
            });
          } else {
            http.post("/release/update", this.release).then((res) => {
              if (res.data.status == 1) {
                this.$message({
                  type: "success",
                  message: "The release has been saved successfully!",
                });
                this.$emit("confirm");
              } else {
                this.$message.error(res.data.message);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.$emit("cancel");
    },
  },
};
</script>
<style scoped></style>
