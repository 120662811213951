<template>
  <div>
    <div class="header">
      <el-button size="mini" type="primary" @click="handleCreate"
        >Create</el-button
      >
      <el-input
        size="mini"
        style="width: 200px;margin-left: 20px;"
        suffix-icon="el-icon-search"
        placeholder="Search version..."
        clearable
        v-model="kw"
      ></el-input>
      <el-date-picker
        v-model="dateRange"
        type="daterange"
        range-separator="To"
        start-placeholder="Start Date"
        end-placeholder="End Date"
        size="mini"
        style="margin-left: 20px;"
        clearable
      >
      </el-date-picker>
    </div>
    <el-table
      :data="selectedReleases"
      size="mini"
      style="float:left; margin-top: 10px;"
      border
      stripe
      :default-sort="{ prop: 'releaseDate', order: 'descending' }"
    >
      <el-table-column
        prop="version"
        label="Version"
        sortable
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="linuxDownload"
        label="Linux Download"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-link
            type="primary"
            :href="scope.row.linuxDownload"
            target="_blank"
            >{{ scope.row.linuxDownload }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="windowsDownload"
        label="Windows Download"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-link
            type="primary"
            :href="scope.row.windowsDownload"
            target="_blank"
            >{{ scope.row.windowsDownload }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="releaseNoteHtml"
        label="Release Note HTML"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-link
            type="primary"
            :href="scope.row.releaseNoteHtml"
            target="_blank"
            >{{ scope.row.releaseNoteHtml }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="releaseNoteXml"
        label="Release Note XML"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-link
            type="primary"
            :href="scope.row.releaseNoteXml"
            target="_blank"
            >{{ scope.row.releaseNoteXml }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        :formatter="formatter"
        sortable
        prop="releaseDate"
        label="Release Date"
      ></el-table-column>
      <el-table-column label="Action" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            plain
            @click="handleEdit(scope)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            circle
            plain
            @click="handleDelete(scope)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" :visible.sync="dialogVisible">
      <release-detail
        v-if="dialogVisible"
        @confirm="handleConfirm"
        @cancel="handleCancel"
        :editRelease="release"
      ></release-detail>
    </el-dialog>
  </div>
</template>
<script>
import ReleaseDetail from "./ReleaseDetail.vue";
import http from "../utils/http";
export default {
  name: "Release",
  components: {
    ReleaseDetail,
  },
  data() {
    return {
      kw: "",
      dateRange: [],
      title: "Create Release",
      dialogVisible: false,
      release: {},
      releases: [],
    };
  },
  computed: {
    selectedReleases() {
      var round1 = this.releases.filter((value) => {
        return value.version.toLowerCase().indexOf(this.kw.toLowerCase()) > -1;
      });
      var round2 = round1.filter((value) => {
        return this.dateRange != null && this.dateRange.length > 0
          ? new Date(value.releaseDate) >= new Date(this.dateRange[0]) &&
              new Date(value.releaseDate) <= new Date(this.dateRange[1])
          : true;
      });
      return round2;
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    formatter(row) {
      var date = new Date(row.releaseDate);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      var d = date.getDate();
      var releaseDate =
        y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
      return releaseDate;
    },
    get() {
      http.get("/release").then((res) => {
        if (res.data.status == 1) {
          this.releases.splice(0, this.releases.length);
          this.releases = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleEdit(scope) {
      this.title = "Edit Release";
      this.release = scope.row;
      this.dialogVisible = true;
    },
    handleDelete(scope) {
      http.post("/release/deleteById", { _id: scope.row._id }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: "The release has been deleted successfully!",
          });
          this.releases.splice(this.releases.indexOf(scope.row), 1);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleCreate() {
      this.release = {};
      this.title = "Create Release";
      this.dialogVisible = true;
    },
    handleConfirm() {
      this.get();
      this.dialogVisible = false;
    },
    handleCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.header {
  width: 1000px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  float: left;
  /* margin-top: -10px; */
}
</style>
